import styled from "@emotion/styled"
import React from "react"
import { SocialIcon } from "./SocialIcon"

const IconList = styled.ul`
  display: flex;
`

const Icon = styled.li`
  background-color: #fa4616;
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 4px;
  margin-right: 20px;
`

export const FollowUsButtons = () => {
  const icons = ["facebook", "instagram", "linkedin", "twitter"]
  return (
    <IconList>
      {icons.map(icon => (
        <Icon key={icon}>
          <SocialIcon icon={icon} />
        </Icon>
      ))}
    </IconList>
  )
}
