import styled from "@emotion/styled"
import { useLocation } from "@reach/router"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Container from "../../components/container"
import Layout from "../../components/layout"
import { Rtf } from "./Rtf"
import SEO from "../../components/seo"
import { SocialIcon } from "../../components/SocialIcons"

const Hero = styled.div`
  position: relative;
  top: 0;
  left: 50%;
  overflow: hidden;
  width: calc(100% + 2px);
  height: ${props => (props.alignment !== "Full" ? "640px" : "auto")};
  transform: translateX(-50%) translateX(-1px);

  @media screen and (max-width: 768px) {
    height: ${props => (props.alignment !== "Full" ? "400px" : "auto")};
    padding: ${props => (props.alignment !== "Full" ? "20px" : "none")};
  }
`

const FloatedImage = styled.div`
  float: left;
  width: 300px;
  height: auto;
  margin-right: 20px;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    float: none;
    margin-right: 0;
    margin-bottom: 20px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
`

const Body = styled.section`
  padding: 40px 0;
  color: #fff;
  margin: 0 20px;

  a {
    color: #fa4616;
    &:hover {
      color: #676779;
    }
  }
  @media screen and (max-width: 540px) {
    padding-bottom: 20px;
  }
`

const Title = styled.h1`
  color: #fff;
  font-family: Barlow Condensed;
  font-size: 2.5em;
  font-weight: bold;
  text-transform: uppercase;
`

const AboutTitle = styled.h3`
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
`

const Sharing = styled.section`
  padding: 0 0 40px 0;
  @media screen and (max-width: 1068px) {
    padding: 0 20px 40px 20px;
  }
`

const ShareTitle = styled.h2`
  font-family: Barlow Condensed;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 12px;
  text-transform: uppercase;
`

const ShareButtons = styled.div`
  display: flex;
  & > * {
    background-color: #fa4616;
    border-radius: 4px;
    padding: 10px;
    width: 50px;
    height: 50px;
    &:not(:last-of-type) {
      margin-right: 8px;

      @media screen and (max-width: 939px) {
        margin-right: 5px;
      }
    }
  }
`

const shareLinks = {
  facebook: {
    createUrl: ({ hostName, slug }) =>
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        `${hostName}/news/${slug}`
      )}`,
    windowSize: {
      width: 550,
      height: 870,
    },
  },
  // Here in case we want it later
  // linkedin: ({ hostName, slug, postTitle, postSummary }) =>
  //   `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
  //     `${hostName}/news/${slug}`
  //   )}&title=${encodeURIComponent(postTitle)}&summary=${encodeURIComponent(
  //     postSummary
  //   )}&source=${encodeURIComponent(`Pit Pay`)}`,
  twitter: {
    createUrl: ({ hostName, slug, tweetText }) =>
      `https://twitter.com/share?text=${encodeURIComponent(
        `${tweetText} @pitpayapp`
      )}&url=${encodeURIComponent(`${hostName}/news/${slug}`)}`,
    windowSize: {
      width: 580,
      height: 350,
    },
  },
}

const BlogPost = props => {
  const post = props.data?.contentfulNewsPost

  const { origin } = useLocation()

  if (!post) return null

  const {
    aboutOther,
    aboutPitPay,
    body,
    description,
    excerptImage,
    heroImage,
    heroImageAlignment,
    shareText,
    slug,
    socialImage,
    subhead,
    title,
  } = post

  const tweetText = shareText ?? title

  function openShareWindow(service) {
    const { windowSize, createUrl } = shareLinks[service] ?? {}
    if (!windowSize || !createUrl) return
    const href = createUrl({
      hostName:
        process.env.NODE_ENV === "development" ? "https://pitpay.com" : origin,
      slug,
      ...(service === "twitter" ? { tweetText } : undefined),
    })
    const windowName = `${service}-share`
    const { width, height } = windowSize
    window.open(href, windowName, `width=${width},height=${height}`)
  }

  const socialImageSrc = socialImage
    ? socialImage.fixed.src
    : excerptImage
    ? excerptImage.fluid.src
    : heroImage
    ? heroImage.fluid.src
    : undefined

  /**
   * Possible value here are 'Left', 'Right', 'Center', 'Full'.
   * This is to make it nice for the editors. We need to translate
   * this into values usable by CSS. We'll default to 'center'.
   */

  return (
    <Layout>
      <SEO
        title={title}
        description={description.description}
        image={socialImageSrc}
      />
      {heroImage && heroImageAlignment !== "Float" && (
        <Hero alignment={heroImageAlignment ?? "Full"}>
          <Img
            fluid={heroImage.fluid}
            alt={heroImage.description}
            style={{
              width: "100%",
              height: "100%",
            }}
            imgStyle={{
              objectFit: "contain",
              ...(heroImageAlignment === "Center"
                ? { paddingTop: 60 }
                : undefined),
              ...(!heroImageAlignment || heroImageAlignment === "Full"
                ? { objectFit: "cover" }
                : undefined),
            }}
          />
        </Hero>
      )}
      <Container>
        <Body>
          {heroImage && heroImageAlignment === "Float" && (
            <FloatedImage>
              <Img
                fluid={heroImage.fluid}
                alt={heroImage.description}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </FloatedImage>
          )}
          <Title>{title}</Title>
          <h2
            className="subtitle"
            style={{ textTransform: "uppercase", color: "#fff" }}
          >
            {subhead}
          </h2>
          <Rtf data={body} />
          {aboutPitPay && (
            <>
              <AboutTitle>{aboutPitPay.header}</AboutTitle>
              <Rtf data={aboutPitPay.body} />
            </>
          )}
          {aboutOther && <Rtf data={aboutOther} />}
        </Body>
        <Sharing>
          <ShareTitle>Share this Story</ShareTitle>
          <ShareButtons>
            <SocialIcon
              icon="facebook"
              onClick={() => openShareWindow("facebook")}
              role="button"
              width={50}
            />
            <SocialIcon
              icon="twitter"
              onClick={() => openShareWindow("twitter")}
              role="button"
              width={50}
            />
          </ShareButtons>
        </Sharing>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulNewsPost(slug: { eq: $slug }) {
      aboutPitPay {
        header
        body {
          raw
        }
      }
      aboutOther {
        raw
      }
      body {
        raw
        #   references {
        #     ... on ContentfulAsset {
        #       # Due to a bug, removing __typename will cause this to break.
        #       __typename
        #       contentful_id
        #       description
        #       fluid(maxWidth: 600) {
        #         ...GatsbyContentfulFluid
        #       }
        #       file {
        #         url
        #         details {
        #           image {
        #             height
        #             width
        #           }
        #         }
        #       }
        #     }
        #   }
      }
      description {
        description
      }
      slug
      socialImage {
        fixed(height: 630) {
          src
        }
      }
      shareText
      excerptImage {
        fluid(maxWidth: 2000) {
          src
        }
      }
      heroImage {
        description
        fluid(maxWidth: 2000) {
          ...GatsbyContentfulFluid
        }
      }
      heroImageAlignment
      subhead
      title
    }
  }
`

export default BlogPost
