import styled from "@emotion/styled"
import PropTypes from "prop-types"
import React from "react"
import { FacebookIcon } from "./FacebookIcon"
import { InstagramIcon } from "./InstagramIcon"
import { LinkedInIcon } from "./LinkedInIcon"
import { TwitterIcon } from "./TwitterIcon"

const Container = styled.div`
  color: ${props => props.color};
  cursor: pointer;
  width: ${props => props.width}px;
  height: ${props => props.width}px;
`

const services = {
  facebook: {
    icon: FacebookIcon,
    url: "https://www.facebook.com/PitPayApp",
  },
  instagram: {
    icon: InstagramIcon,
    url: "https://www.instagram.com/pitpayapp/",
  },
  linkedin: {
    icon: LinkedInIcon,
    url: "https://www.linkedin.com/company/pitpay/about/",
  },
  twitter: {
    icon: TwitterIcon,
    url: "https://twitter.com/pitpayapp",
  },
}

export const SocialIcon = props => {
  const {
    className,
    color = "white",
    icon: serviceProp,
    onClick,
    role = "link",
    width = 30,
  } = props

  const service = services[serviceProp]

  if (!service) return null

  const Icon = service.icon
  const isButton = role === "button"

  const containerProps = {
    className,
    color,
    ...(isButton ? { onClick } : undefined),
    ...(isButton ? { onKeyPress: onClick } : undefined),
    ...(isButton ? { role } : undefined),
    ...(isButton ? { tabIndex: 0 } : undefined),
    width,
  }

  const components = (
    <Container {...containerProps}>
      <Icon />
    </Container>
  )

  return role === "link" ? (
    <a href={service.url} target="_blank" rel="noreopener noreferrer">
      {components}
    </a>
  ) : (
    components
  )
}
SocialIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.oneOf(["twitter", "linkedin", "instagram", "facebook"]),
  onClick: PropTypes.func,
  role: PropTypes.oneOf(["link", "button"]),
  width: PropTypes.number,
}
